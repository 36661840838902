import React from "react";
import GSCMaterial from "@gsc/material";

import GSCNavBar from "../navbar";
import "./Layout.css";
import "@gsc/material/ProximaNovaFontFace.css";
// TODO: CHANGE THE PRESET 56PX
const Layout = props => (
    <GSCMaterial>
        <div>
            <GSCNavBar {...props} />
        </div>
        <div className="App" style={{ marginTop: "56px" }}>
            {props.children}
        </div>
    </GSCMaterial>
);

export default Layout;
