import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import MapIcon from "@material-ui/icons/Map";
import HomeIcon from "@material-ui/icons/Home";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import { navigate, Link } from "gatsby";

const useStyles = makeStyles({
    root: {
        position: "fixed",
        bottom: "0",
        width: "100%"
    }
});

export default function BottomNavBar(props) {
    const classes = useStyles();
    /* Hack to let gatsby build without causing its compile time SSR to error
     * see this https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
     */
    const value =
        typeof window !== `undefined`
            ? window.location.pathname + window.location.search
            : null;
    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                /* Hack to let gatsby build without causing its compile time SSR to error
                 * see this https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
                 */
                // if (typeof window !== `undefined`) {
                navigate(newValue);
                // }
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction
                label="Home"
                value="/"
                icon={<HomeIcon />}
            />
            <BottomNavigationAction
                label="Map"
                value="/map"
                icon={<MapIcon />}
            />
            <BottomNavigationAction
                label="Scanner"
                value="/scanner"
                icon={<SelectAllIcon />}
            />
            <BottomNavigationAction
                label="Experiences"
                value="/experiences"
                icon={<PlaylistAddCheckIcon />}
            />
        </BottomNavigation>
    );
}
