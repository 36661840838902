import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import AccountIcon from "@material-ui/icons/AccountCircle";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { navDrawerWidth } from "../../config";
import gscLogoInverse from "../../../static/assets/gsclogo-inverse.png";
import BottomNavBar from "./BottomNavBar";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    title: {
        flexGrow: 1
    },
    logo: {
        width: "50px",
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        marginBottom: "0px",
        [theme.breakpoints.up("md")]: {
            width: "80px",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        }
    },
    appLink: {
        textDecoration: "none",
        color: theme.gsc.offwhite,
        "&:hover": {
            textDecoration: "none",
            color: theme.gsc.offwhite
        }
    }
}));

const GSCNavBar = props => {
    const classes = useStyles();

    const [navDrawerOpen, setNavDrawerOpen] = React.useState(false);

    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Link to="/" className={classes.appLink}>
                        <img
                            className={classes.logo}
                            src={gscLogoInverse}
                            alt=""
                        />
                    </Link>
                </Toolbar>
                <BottomNavBar {...props} />
            </AppBar>
        </div>
    );
};

export default GSCNavBar;
